<template>
  <v-menu>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn color="warning" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }"> List </v-btn>
        </template>
        <span>Categories, Addons, Options And Items</span>
      </v-tooltip>
    </template>
    <v-list>
      <span v-for="(item, index) in addList" :key="index">
        <v-list-item :to="item.link">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </span>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    BranchId: { type: Number, required: true },
  },
  setup(props) {
    const addList = [
      { title: 'Category', link: { name: 'CategoryList', params: { BranchId: props.BranchId } } },

      {
        title: 'Addon',
        link: { name: 'AddonList', params: { BranchId: props.BranchId } },
      },
      {
        title: 'Option',
        link: { name: 'OptionList', params: { BranchId: props.BranchId } },
      },
      { title: 'Item', link: { name: 'ItemList', params: { BranchId: props.BranchId } } },
    ]
    return { addList }
  },
}
</script>
