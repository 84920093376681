import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" List ")])]}}],null,true)},[_c('span',[_vm._v("Categories, Addons, Options And Items")])])]}}])},[_c(VList,_vm._l((_vm.addList),function(item,index){return _c('span',{key:index},[_c(VListItem,{attrs:{"to":item.link}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }