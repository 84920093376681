import router from '@/router'
import { GetByBranchId } from '@core/api/Categories'
import { ref, watch } from '@vue/composition-api'

export default function useCategoryList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Description', value: 'Description', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const CategoryListTable = ref([])
  const searchQuery = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalCategoryListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const categoryTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchCategories = () => {
    GetByBranchId({ BranchId: BranchId.value, Any: searchQuery.value })
      .then(response => {
        // handle success
        CategoryListTable.value = response
        totalCategoryListTable.value = response.lenght
        categoryTotalLocal.value = response.lenght
      })
      .catch(error => {
        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    fetchCategories()
  })
  return {
    CategoryListTable,
    tableColumns,
    searchQuery,
    BranchId,
    statusFilter,
    totalCategoryListTable,
    options,
    categoryTotalLocal,
    fetchCategories,
  }
}
